import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Summary from '../components/Summary';
import Validator from '../components/Validator';
import '../assets/css/styles.css';
import { projectName } from '../data/API';

class Validators extends React.Component {
  componentDidMount(){
    document.title = `Validators | ${projectName} Scan`;
  }
  componentDidUpdate(){
    console.log(this.props.match.params.address);
  }
  render() {
    return(
      <div className="contents">
        <p className="content-title">Summary</p>
        <Summary/>
        <p className="content-title">Validators</p>
        <BrowserRouter>
          <Switch>
            <Route exact path="/validators/inactive">
              <Validator total="all" status="inactive"/>
            </Route>
            <Route exact path="/validators/active">
              <Validator total="all" status="active"/>
            </Route>
            <Route exact path="/validators">
              <Validator total="all" status="active"/>
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

export default Validators;