import React from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';
import { apiURL, prefixDefault, prefixValidator, ibcEnable } from '../data/API';
import '../assets/css/styles.css';
import { connect } from 'react-redux';
import {getPathName} from '../redux/actions/getData';
import thecodes_white from '../thecodes_white.png';

class Header extends React.Component {
  componentWillMount() {
    this.props.getPathName(window.location.pathname);
  }
  state = {
    headerDisplayMobile: "header-contents",
  }
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  headerOpenHandler = () => {
    this.setState({headerDisplayMobile: "header-contents show-header-mobile"})
  }
  headerCloseHandler = (event) => {
    this.setState({headerDisplayMobile: "header-contents"})
  }
  searchHandle(searchString){
    if(searchString){
      if(Number.isInteger(parseInt(searchString)) && searchString.length < 44){
        window.location.replace(`/block/${parseInt(searchString)}`);
      }else if(searchString.length === prefixDefault.length+39 && searchString.includes(prefixDefault)){
        window.location.replace(`/address/${searchString}`);
      }else if(searchString.length === prefixValidator.length+39 && searchString.includes(prefixValidator)){
        window.location.replace(`/validator/${searchString}`);
      }else if(searchString.length === 64){
        Axios.get(`${apiURL}/search?hash=${searchString}`)
        .then(getType => {
          if(getType.data.result){
            if(getType.data.data === 'transaction'){
              window.location.replace(`/tx/${searchString}`);
            }else if(Number.isInteger(getType.data.data)){
              window.location.replace(`/block/${getType.data.data}`);
            }
          }
        })
      }
    }
  }
  render() {
    return (
      <div>
        <div className="header-div px-auto">
          <p className="header-title my-auto"><Link to="/" className="header-title" onClick={() => this.changePath("/")}><img alt="TheCodes" src={thecodes_white} style={{"height":"50px","width": "auto"}} /></Link></p>
          <i className="header-open-button icon bi-list my-auto" onClick={this.headerOpenHandler}></i>
          <div className={this.state.headerDisplayMobile}>
            <p className="header-close-button my-auto" onClick={this.headerCloseHandler}>Close <i className="icon bi-arrow-bar-right"></i></p>
            {
              this.props.pathName === "/" ? 
                <Link className="header-menu header-menu-active my-auto text-center" onClick={() => this.changePath("/")} to="/"><i className="icon bi-columns-gap"></i> Home</Link>
              :
              <Link className="header-menu my-auto text-center" onClick={() => this.changePath("/")} to="/"><i className="icon bi-columns-gap"></i> Home</Link>
            }
            {
              this.props.pathName === "/blocks" ? 
                <Link className="header-menu header-menu-active my-auto text-center" onClick={() => this.changePath("/blocks")} to="/blocks"><i className="icon bi-box"></i> Blocks</Link>
              :
              <Link className="header-menu my-auto text-center" onClick={() => this.changePath("/blocks")} to="/blocks"><i className="icon bi-box"></i> Blocks</Link>
            }
            {
              this.props.pathName === "/txs" ? 
                <Link className="header-menu header-menu-active my-auto text-center" onClick={() => this.changePath("/txs")} to="/txs"><i className="icon bi-list-nested"></i> Transactions</Link>
              :
                <Link className="header-menu my-auto text-center" onClick={() => this.changePath("/txs")} to="/txs"><i className="icon bi-list-nested"></i> Transactions</Link>
            }
            {
              ibcEnable ?
                this.props.pathName === "/ibcs" ? 
                  <Link className="header-menu header-menu-active my-auto text-center" onClick={() => this.changePath("/ibcs")} to="/ibcs"><i className="icon bi-broadcast"></i> IBC</Link>
                :
                  <Link className="header-menu my-auto text-center" onClick={() => this.changePath("/ibcs")} to="/ibcs"><i className="icon bi-broadcast"></i> IBC</Link>
              : null
            }
            {
              this.props.pathName === "/validators" ? 
                <Link className="header-menu header-menu-active my-auto text-center" onClick={() => this.changePath("/validators")} to="/validators"><i className="icon bi-people"></i> Validators</Link>
              :
                <Link className="header-menu my-auto text-center" onClick={() => this.changePath("/validators")} to="/validators"><i className="icon bi-people"></i> Validators</Link>
            }
            {/* {
              this.props.pathName === "/proposals" ? 
                <Link className="header-menu header-menu-active my-auto text-center" onClick={() => this.changePath("/proposals")} to="/proposals"><i className="icon bi-newspaper"></i> Proposals</Link>
              :
                <Link className="header-menu my-auto text-center" onClick={() => this.changePath("/proposals")} to="/proposals"><i className="icon bi-newspaper"></i> Proposals</Link>
            } */}
          </div>
        </div>
        {
          this.props.pathName === "/" ?
            null
          :
          <>
          <div className="header-search-div">
            <div className="header-search my-auto" style={{"display": "flex"}}>
              <input className="header-input" type="text" value={this.state.searchString} onChange={e => this.setState({searchString: e.target.value})} placeholder="Search by hash, block height, address..."/>
              <button className="header-button my-auto" onClick={() => this.searchHandle(this.state.searchString)} type="button">Search</button>
            </div>
          </div>
          <div className="header-search-mobile my-auto">
          <form>
            <input className="header-input" type="text" value={this.state.searchString} onChange={e => this.setState({searchString: e.target.value})} placeholder="Search by hash, block height, address..."/>
            <button className="header-button my-auto" onClick={() => this.searchHandle(this.state.searchString)} type="button"><i className="icon bi-search"></i></button>
          </form>
        </div>
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);