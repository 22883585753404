import React from 'react';
import '../assets/css/styles.css';
import { apiURL, denomination, projectName } from '../data/API';
import Axios from 'axios';
import QRCode from "react-qr-code";
import Tooltip from "@material-ui/core/Tooltip";
import { PieChart } from 'react-minimal-pie-chart';
import moment from 'moment';
import person from '../person.svg';
import TransactionDetail from '../components/TransactionDetail';
import {Link} from 'react-router-dom';

class Account extends React.Component {
  state={}
  componentWillMount(){
    this.getData()
    document.title = `Address ${this.props.match.params.address} | ${projectName} Scan`;
  }
  componentWillUpdate(){
    if(this.state.address !== this.props.match.params.address) {
      this.getData();
    }
  }
  getData = async() => {
    Axios.get(`${apiURL}/balance?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          checkBalance: true,
          address: response.data.data.address,
          balance: response.data.data.balance,
          sequence: response.data.data.sequence,
          rewards: response.data.data.rewards,
          delegate: response.data.data.delegate,
          undelegate: response.data.data.undelegate,
        })
      }else{
        console.log("Failed to fetch balance")
      }
    })
    .catch(err => {
      console.log("Failed to fetch balance")
    })
    Axios.get(`${apiURL}/delegations?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          delegates: response.data.data,
          checkDelegations: true
        })
      }else{
        console.log("Failed to fetch delegation")
      }
    })
    Axios.get(`${apiURL}/undelegations?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          undelegates: response.data.data,
          checkUndelegations: true
        })
      }else{
        console.log("Failed to fetch undelegation")
      }
    })
    .catch(err => {
      console.log("Failed to fetch transactions")
    })
    Axios.get(`${apiURL}/txs?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          txs: (response.data.data).reverse(),
          checkTxs: true
        })
      }else{
        console.log("Failed to fetch transaction")
      }
    })
    .catch(err => {
      console.log("Failed to fetch transactions")
    })
  }
  renderDelegate = () => {
    if(this.state.delegates){
      var delegates = [];
      (this.state.delegates).forEach(delegate => {
        var image = person;
        if(delegate.imageUrl){
          image = delegate.imageUrl
        }
        delegates.push(
          <div className="summary-home rounded-0 border-bottom">
            <p className="text-hash" style={{"marginBottom": "5px"}}><Tooltip interactive title={delegate.operatorAddress} placement="top-start"><Link to={`/validator/${delegate.operatorAddress}`}><img className="image-validator" src={image} alt={delegate.operatorAddress}/>{delegate.moniker}</Link></Tooltip></p>
            <div className="row">
              <div className="col-xl-1 col-4" style={{"marginBottom": "-15px"}}>
                <p>Total</p>
              </div>
              <div className="col-xl-5 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p>{(parseInt(delegate.balance)/10**6).toLocaleString(undefined, {minimumFractionDigits: 6})} <b>{denomination}</b></p>
              </div>
              <div className="col-xl-1 col-4" style={{"marginBottom": "-15px"}}>
                <p>Reward</p>
              </div>
              <div className="col-xl-5 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p>{(parseInt(delegate.reward)/10**6).toLocaleString(undefined, {minimumFractionDigits: 6})} <b>{denomination}</b></p>
              </div>
            </div>
          </div>
        )
      })
      return delegates
    }
  }
  renderUndelegate = () => {
    if(this.state.undelegates){
      var undelegates = [];
      (this.state.undelegates).forEach(undelegate => {
        var image = person;
        if(undelegate.imageUrl){
          image = undelegate.imageUrl
        }
        undelegates.push(
          <div className="summary-home rounded-0 border-bottom">
            <p className="text-hash" style={{"marginBottom": "5px"}}><Tooltip interactive title={undelegate.operatorAddress} placement="top-start"><Link to={`/validator/${undelegate.operatorAddress}`}><img className="image-validator" src={image} alt={undelegate.operatorAddress}/>{undelegate.moniker}</Link></Tooltip></p>
            <div className="row">
              <div className="col-xl-1 col-4" style={{"marginBottom": "-15px"}}>
                <p>Total</p>
              </div>
              <div className="col-xl-5 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p>{(parseInt(undelegate.balance)/10**6).toLocaleString(undefined, {minimumFractionDigits: 6})} <b>{denomination}</b></p>
              </div>
              <div className="col-xl-3 col-4" style={{"marginBottom": "-15px"}}>
                <p>Start Height</p>
              </div>
              <div className="col-xl-3 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p>{parseInt(undelegate.startHeight).toLocaleString()}</p>
              </div>
              <div className="col-xl-2 col-4" style={{"marginBottom": "-15px"}}>
                <p>End Time</p>
              </div>
              <div className="col-xl-10 col-8" style={{"marginBottom": "-15px", "textAlign": "right"}}>
                <p>{`${moment(undelegate.endTime).format("DD/MM/YY, HH:mm")} (${moment(undelegate.endTime).fromNow()})`}</p>
              </div>
            </div>
          </div>
        )
      })
      return undelegates
    }
  }

  render(){
    return(
      <div className="contents">
        <p className="content-title">Account Details</p>
        {
          this.state.checkBalance === true && this.state.address === this.props.match.params.address ?
          <>
          <div className="summary-home shadow-sm">
            <div className="row">
              <div className="col-md-1 col-3" style={{"textAlign": "center"}}>
                <QRCode value={this.props.match.params.address} bgColor="#FAFAFA" size="60" />
              </div>
              <div className="col-md-11 col-9">
                <p className="content-table-text"><b>{this.props.match.params.address}</b> <Tooltip interactive title={`Copy`} placement="top-start"><i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.props.match.params.address)}}></i></Tooltip></p>
                <p className="content-table-text" style={{"marginBottom": "-5px", "marginTop": "-5px"}}>{((parseInt(this.state.balance)+parseInt(this.state.rewards)+parseInt(this.state.delegate)+parseInt(this.state.undelegate))/10**6).toLocaleString(undefined, {minimumFractionDigits: 6})} <b className="title-underline">{denomination}</b></p>
              </div>
            </div>
          </div>
          <p className="content-title">Balance Details</p>
          <div className="summary-home shadow-sm">
            <div className="row">
              <div className="col-md-2" style={{"textAlign": "center"}}>
                <div className="pie-chart">
                  <PieChart
                    data={[
                      { title: 'Available', value: parseInt(this.state.balance)/10**6, color: '#2A9D8F' },
                      { title: 'Delegated', value: parseInt(this.state.delegate)/10**6, color: '#F4A261' },
                      { title: 'Reward', value: parseInt(this.state.rewards)/10**6, color: '#264653' },
                      { title: 'Unbonding', value: parseInt(this.state.undelegate)/10**6, color: '#E76F51' },
                    ]}
                    lineWidth = {30}
                    animate
                  />
                </div>
              </div>
              <div className="col-md-10" style={{"marginTop": "auto"}}>
                <div className="row">
                  <div className="col-md-3">
                    <p className="content-table-title"><i style={{"color": "#2A9D8F"}} className="bi bi-circle-fill"></i> Available</p>
                  </div>
                  <div className="col-md-9">
                    <p className="content-table-text">{(parseInt(this.state.balance)/10**6).toLocaleString(undefined, {minimumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#2A9D8F"}}>{denomination}</b></p>
                  </div>
                  <div className="col-md-3">
                    <p className="content-table-title"><i style={{"color": "#F4A261"}} className="bi bi-circle-fill"></i> Delegated</p>
                  </div>
                  <div className="col-md-9">
                    <p className="content-table-text">{(parseInt(this.state.delegate)/10**6).toLocaleString(undefined, {minimumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#F4A261"}}>{denomination}</b></p>
                  </div>
                  <div className="col-md-3">
                    <p className="content-table-title"><i style={{"color": "#264653"}} className="bi bi-circle-fill"></i> Reward</p>
                  </div>
                  <div className="col-md-9">
                    <p className="content-table-text">{(parseInt(this.state.rewards)/10**6).toLocaleString(undefined, {minimumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#264653"}}>{denomination}</b></p>
                  </div>
                  <div className="col-md-3">
                    <p className="content-table-title"><i style={{"color": "#E76F51"}} className="bi bi-circle-fill"></i> Unbonding</p>
                  </div>
                  <div className="col-md-9">
                    <p className="content-table-text">{(parseInt(this.state.undelegate)/10**6).toLocaleString(undefined, {minimumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#E76F51"}}>{denomination}</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            !this.state.checkDelegations && !this.state.checkUndelegations ?
            <>
              <p className="content-title">Delegation Details</p>
              <div className="summary-home shadow-sm" style={{"textAlign": "center"}}>
                <div class="o1"></div>
                <div class="o2"></div>
                <div class="o3"></div>
              </div>
            </>
            :
            <>
              <div className="row">
                <div className="col-md-6">
                  <p className="content-title">Delegation Details</p>
                  {
                    parseInt(this.state.delegate) > 0 ?
                      <div className="shadow-sm border overflow-custom" style={{"maxHeight": "300px", "overflow": "auto","background": "white","paddingTop":"10px"}}>
                        {this.renderDelegate()}
                      </div>
                    : null
                  }
                </div>
                <div className="col-md-6">
                  <p className="content-title">Unbonding Details</p>
                  {
                    parseInt(this.state.undelegate) > 0 ?
                      <div className="shadow-sm border overflow-custom" style={{"maxHeight": "300px", "overflow": "auto","background": "white","paddingTop":"10px"}}>
                        {this.renderUndelegate()}
                      </div>
                    : null
                  }
                </div>
              </div>
              </>
          }
          <p className="content-title">Transactions</p>
            {
              this.state.txs && this.state.checkTxs ?
                <div className="shadow-sm border overflow-custom" style={{"maxHeight": "600px", "overflow": "auto","background": "white","paddingTop":"10px"}}>
                  <TransactionDetail jsonTx={this.state.txs} address={this.props.match.params.address}/>
                </div>
              :
              <div className="summary-home shadow-sm" style={{"textAlign": "center"}}>
                <div class="o1"></div>
                <div class="o2"></div>
                <div class="o3"></div>
              </div>
            }
          </>
          : 
          <div className="summary-home shadow-sm" style={{"textAlign": "center"}}>
            <div class="o1"></div>
            <div class="o2"></div>
            <div class="o3"></div>
          </div>
        }
      </div>
    )
  }
}

export default Account;