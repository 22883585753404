import React from 'react';
import { connect } from 'react-redux';
import {getTransactions, getPathName} from '../redux/actions/getData';
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from 'react-router-dom';
import { denomination, prefixDefault } from '../data/API';
import moment from 'moment';

class Transaction extends React.Component {
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  loopData = (numData, grid) => {
    if((this.props.fullData.latestTransaction).length > 0) {
      var totalData = 0;
      var txs = [];
      var colClass1 = "col-md-1 col-5"
      var colClass2 = "col-md-2 col-6"
      var colClass3 = "col-md-2 col-6"
      var classStyle = "summary-home rounded-0"
      if(grid){
        colClass1 = "col-md-3 col-5"
        colClass2 = "col-md-3 col-6"
        colClass3 = "col-md-6 col-6"
        classStyle = "summary-home shadow-sm border"
      }
      (this.props.fullData.latestTransaction).forEach(tx => {
        if(totalData < numData) {
          if(totalData !== numData-1){
            var borderStyle = "border-bottom";
          }
          txs.push(
            <Link to={`/tx/${tx.txHash}`} onClick={() => this.changePath("/tx")}>
              <div className={`${classStyle} ${borderStyle}`}>
                <div className="row">
                  <div className="col-md-3 col-7">
                    {
                      tx.status === true ?
                        <p className="text-hash" style={{"textTransform": "capitalize"}}><i className="icon bi-check-circle-fill" style={{"color": "green"}}></i> {tx.txType}</p>
                      :
                      <p className="text-hash" style={{"textTransform": "capitalize"}}><Tooltip interactive title={tx.status} placement="top-start"><i className="icon bi-x-circle-fill" style={{"color": "red"}}></i></Tooltip> {tx.txType}</p>
                    }
                  </div>
                  <div className={colClass1}>
                    {
                      !this.props.block ?
                        <p><Link to={`/block/${tx.txHeight}`} onClick={() => this.changePath("/block")}><b>{parseInt(tx.txHeight).toLocaleString()}</b></Link></p>
                      : <p>{moment(tx.txTime).format("D/MM/YY HH:mm:ss")}</p>
                    }
                  </div>
                  <div className={colClass2}>
                    <p><b>{(parseInt(tx.txAmount)/10**6).toLocaleString(undefined, {minimumFractionDigits: 3})}</b> {denomination}</p>
                  </div>
                  <div className={colClass2}>
                    {
                      this.props.gas ?
                        <p className="text-hash">{parseInt(tx.txGasUsed).toLocaleString()} / {parseInt(tx.txGasWanted).toLocaleString()}</p>
                      :
                        <p>Fee <b>{(parseInt(tx.txFee)/10**6).toFixed(5)}</b></p>
                    }
                  </div>
                  <div className={colClass3}>
                    {
                      !this.props.sender ?
                        tx.txSender ?
                          (tx.txSender).includes('valoper') ?
                            <p className="text-hash">From <Link to={`/validator/${tx.txSender}`} onClick={() => this.changePath("/validator")}><b>{tx.txSender}</b></Link></p>
                          : <p className="text-hash">From <Link to={`/address/${tx.txSender}`} onClick={() => this.changePath("/account")}><b>{tx.txSender}</b></Link></p>
                        : null
                      :
                        tx.txReceiver ?
                          tx.txReceiver && (tx.txReceiver).includes(prefixDefault) ?
                            (tx.txReceiver).includes('valoper') ?
                              <p className="text-hash">To <Link to={`/validator/${tx.txReceiver}`} onClick={() => this.changePath("/validator")}><b>{tx.txReceiver}</b></Link></p>
                            : <p className="text-hash">To <Link to={`/address/${tx.txReceiver}`} onClick={() => this.changePath("/account")}><b>{tx.txReceiver}</b></Link></p>
                          : <p className="text-hash">To <b>{tx.txReceiver}</b></p>
                        :
                          tx.txReceiver && (tx.txReceiver).includes(prefixDefault) ?
                            (tx.txReceiver).includes('valoper') ?
                              <p className="text-hash">To <Link to={`/validator/${tx.txReceiver}`} onClick={() => this.changePath("/validator")}><b>{tx.txReceiver}</b></Link></p>
                            : <p className="text-hash">To <Link to={`/address/${tx.txReceiver}`} onClick={() => this.changePath("/account")}><b>{tx.txReceiver}</b></Link></p>
                          :
                            tx.txAdditional.to ?
                              <p className="text-hash">To <b>{tx.txAdditional.to}</b></p>
                            : null
                    }
                  </div>
                  <div className={colClass3}>
                    <p className="text-hash"><b>{tx.txHash}</b></p>
                  </div>
                </div>
              </div>
            </Link>
          )
        }
        totalData++;
      })
    }
    return txs;
  }
  render() {
    return (
      <div>
        {
          (this.props.fullData.latestTransaction).length > 0 ?
            <div>
              {this.loopData(this.props.total, this.props.grid)}
              {
                this.props.grid ?
                  <>
                    <Link to={"/txs"} onClick={() => {this.changePath("/txs"); window.scrollTo({top: 0, behavior: 'smooth'})}}>
                      <div className="summary-home shadow-sm border" style={{"height":"38px","paddingTop": "5px"}}>
                        <p style={{"textAlign": "center"}}><i className="bi bi-caret-right-fill"></i> Show more...</p>
                      </div>
                    </Link>
                  </>
                :null
              }
            </div>
          :
          <div className="summary-home content shadow-sm" style={{"textAlign": "center"}}>
            <div class="o1"></div>
            <div class="o2"></div>
            <div class="o3"></div>
          </div>
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    fullData: state.user,
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getTransactions,
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);