import React from 'react';
import Summary from '../components/Summary';
import Block from '../components/Block';
import '../assets/css/styles.css';
import { projectName } from '../data/API';

class Blocks extends React.Component {
  componentDidMount(){
    document.title = `Blocks | ${projectName} Scan`;
  }
  render() {
    return(
      <div className="contents">
        <p className="content-title">Summary</p>
        <Summary/>
        <p className="content-title">Blocks</p>
        <div className="shadow-sm border" style={{"background": "white","paddingTop":"10px","borderRadius": "10px"}}>
          <Block total="20"/>
        </div>
      </div>
    )
  }
}

export default Blocks;