import React from 'react';
import { connect } from 'react-redux';
import {getBlocks, getValidators, getSummary, getTransactions } from '../redux/actions/getData';

class Summary extends React.Component {
  state={}
  componentDidMount() {
    this.props.getValidators();
    this.props.getBlocks();
    this.props.getSummary();
    this.props.getTransactions();
    setInterval(() => {
      this.props.getSummary();
      this.props.getBlocks();
      this.props.getTransactions();
      this.props.getValidators();
    }, 4000);
  }
  render() {
    return (
      <div style={{"width": "100%"}}>
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <div className="summary-home shadow-sm border">
                  {(this.props.fullData.latestBlock).length > 0 ?
                    <p className="summary-content">{parseInt(this.props.fullData.latestBlock[0].blockHeight).toLocaleString()}</p>
                  : <p className="summary-content">-</p>}
                  <p className="summary-title"><i className="icon bi-box"></i> Current Height</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="summary-home shadow-sm border">
                  {(this.props.fullData.latestBlock).length > 0 ?
                    <p className="summary-content">{this.props.fullData.latestBlock[0].numTx}</p>
                  : <p className="summary-content">-</p>}
                  <p className="summary-title"><i className="icon bi-receipt"></i> Transaction(s)</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="summary-home shadow-sm border">
                  <p className="summary-content">{(this.props.fullData.activeValidators).length} / {this.props.fullData.maxValidator}</p>
                  <p className="summary-title"><i className="icon bi-people"></i> Active Validators</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="summary-home shadow-sm border">
                  {
                    (this.props.fullData.latestBlock).length > 0 ?
                    <p className="summary-content">{parseFloat(((this.props.fullData.latestBlock[0].blockTime-this.props.fullData.blockFirst)/this.props.fullData.latestBlock[0].blockHeight)/1000).toFixed(3)}s</p>
                    : <p className="summary-content">-</p>}
                  <p className="summary-title"><i className="icon bi-lightning-charge"></i> Avg. Block Speed</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="summary-home shadow-sm border">
                  <p className="summary-content">{parseInt((this.props.fullData.totalSupply)/10**6).toLocaleString()}</p>
                  <p className="summary-title"><i className="icon bi-gem"></i> Total Supply</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="summary-home shadow-sm border">
                  <p className="summary-content">{parseInt((this.props.fullData.bondedCoins)/10**6).toLocaleString()}</p>
                  <p className="summary-title"><i className="icon bi-bounding-box"></i> Bonded Coins</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <div className="summary-home shadow-sm border">
                  <p className="summary-content">-</p>
                  <p className="summary-title"><i className="icon bi-graph-up"></i> Price</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="summary-home shadow-sm border">
                  <p className="summary-content">-</p>
                  <p className="summary-title"><i className="icon bi-cash-coin"></i> Market Cap</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getBlocks,
  getValidators,
  getSummary,
  getTransactions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary);