import React from 'react';

class Proposals extends React.Component {
  render() {
    return(
      <div>
        <h1>Proposals page</h1>
      </div>
    )
  }
}

export default Proposals;