const init_state = {
  syncing: true,
  statusCodeBlock: '',
  statusCodeTransaction: '',
  maxValidator: '0',
  totalSupply: '0',
  bondedCoins: '0',
  latestBlock: [],
  latestTransaction: [],
  latestIBC: [],
  activeValidators: [],
  inactiveValidators: [],
  proposals: [],
  pathName: "",
}

const reducer = (state = init_state, action) =>  {
  switch (action.type) {
    case "GET_PATH_NAME":
      return {...state, pathName: action.data}
    case "GET_BLOCK":
      return {...state, statusCodeBlock: action.status, latestBlock: action.data};
    case "SUMMARY_TRANSACTION":
      return {...state, totalTransactions: action.data};
    case "SUMMARY_MOST":
      return {...state, maxValidator: action.data.maxValidator, bondedCoins: action.data.bondedCoins, syncing: action.data.syncStatus, totalSupply: action.data.totalSupply, blockFirst: action.data.blockFirst};
    case "GET_TRANSACTION":
        return {...state, statusCodeTransaction: action.status, latestTransaction: action.data};
    case "SUMMARY_VALIDATOR":
      if(action.data !== init_state.activeValidators){
        return {...state, activeValidators: action.data.activeValidators, inactiveValidators: action.data.inactiveValidators};
      }
      break;
    case "GET_IBC":
      return {...state, latestIBC: action.data}
    default:
      return state;
  }
}

export default reducer;