import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {ibcEnable} from './data/API'
import Header from './components/Header';
import Blocks from './pages/Blocks';
import Transactions from './pages/Transactions';
import Validators from './pages/Validators';
import Proposals from './pages/Proposals';
import Home from './pages/Home';
import Block from './pages/Block';
import Transaction from './pages/Transaction';
import Validator from './pages/Validator';
// import Proposal from './pages/Proposal';
import Account from './pages/Account';
import IBC from './pages/IBC';

function App() {
  return (
    
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact component={Blocks} path="/blocks"/>
        <Route exact component={Transactions} path="/txs"/>
        <Route exact component={Validators} path="/validators/inactive"/>
        <Route exact component={Validators} path="/validators/active"/>
        <Route exact component={Validators} path="/validators"/>
        <Route exact component={Proposals} path="/proposals"/>
        <Route exact component={Block} path="/block/:height"/>
        <Route exact component={Transaction} path="/tx/:hash"/>
        <Route exact component={Validator} path="/validator/:address"/>
        {/* <Route exact component={Proposal} path="/proposal/:id"/> */}
        <Route exact component={Account} path="/address/:address"/>
        {
          ibcEnable ? 
            <Route exact component={IBC} path="/ibcs"/>
          : null
        }
        <Route exact component={Home} path="/"/>
      </Switch>
    </BrowserRouter>
    );
}

export default App;
