import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from 'react-router-dom';

class TransactionDetail extends React.Component {
  loopData = () => {
    if(this.props.jsonTx) {
      var txs = [];
      var totalData = 0;
      (this.props.jsonTx).forEach(tx => {
        if(totalData < 200){
          var flowAddress = tx.txSender;
          var flow = "From"
          if(this.props.address && tx.txReceiver){
            if(tx.txSender === this.props.address){
              flowAddress = tx.txReceiver;
              flow = "To"
            }
          }else{
            flowAddress = tx.txSender;
            flow = "From"
          }
          txs.push(
            <Link to={`/tx/${tx.txHash}`}>
              <div className="summary-home rounded-0 border-bottom">
                <div className="row">
                  <div className="col-md-3 col-8">
                    {
                      tx.status === true ?
                        <p className="text-hash" style={{"textTransform": "capitalize"}}><i className="icon bi-check-circle-fill" style={{"color": "green"}}></i> {tx.txType}</p>
                      :
                      <p className="text-hash" style={{"textTransform": "capitalize"}}><Tooltip interactive title={tx.status} placement="top-start"><i className="icon bi-x-circle-fill" style={{"color": "red"}}></i></Tooltip> {tx.txType}</p>
                    }
                  </div>
                  <div className={`col-md-1 col-4`}>
                    <p><Link to={`/block/${tx.txHeight}`}><b>{parseInt(tx.txHeight).toLocaleString()}</b></Link></p>
                  </div>
                  <div className={`col-md-2 col-6`}>
                    <p>Total <b>{(parseInt(tx.txAmount)/10**6).toLocaleString(undefined, {minimumFractionDigits: 3})}</b></p>
                  </div>
                  <div className={`col-md-2 col-6`}>
                    <p>Fee <b>{(parseInt(tx.txFee)/10**6).toLocaleString(undefined, {minimumFractionDigits: 5})}</b></p>
                  </div>
                  <div className={`col-md-2 col-6`}>
                    {
                      flowAddress ?
                        (flowAddress).includes('valoper') ?
                          <p className="text-hash">{flow} <Link to={`/validator/${flowAddress}`}><b>{flowAddress}</b></Link></p>
                        : <p className="text-hash">{flow} <Link to={`/address/${flowAddress}`}><b>{flowAddress}</b></Link></p>
                      : null
                    }
                  </div>
                  <div className={`col-md-2 col-6`}>
                    <p className="text-hash"><b>{tx.txHash}</b></p>
                  </div>
                </div>
              </div>
            </Link>
          )
        }
        totalData++;
      })
      return txs;
    }
    return txs;
  }
  render() {
    return (
      <div>
        {this.loopData()}
      </div>
    )
  }
}

export default TransactionDetail;