import React from 'react';
import { projectName, apiURL } from '../data/API';
import Summary from '../components/Summary';
import IBC from '../components/IBC';
import '../assets/css/styles.css';
import Axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { getIBC, getFilterIBC } from '../redux/actions/getData';
import { connect } from 'react-redux';

class IBCS extends React.Component {
  state = {
    channelValue: "all",
    channelFromText: "All",
    channelToText: "All",
    chainID: false,
    sender: true,
    channel: true,
    block: true
  }
  componentDidMount(){
    this.props.getIBC();
    Axios.get(`${apiURL}/ibcchannel`)
    .then(response => {
      this.setState({
        data: response.data.data
      })
    })
    document.title = `IBC Transactions | ${projectName} Scan`;
  }
  handleChannel(channelValue, channelFromText, channelToText){
    this.setState({
      channelValue,
      channelFromText,
      channelToText
    })
    if(channelValue === "all"){
      this.props.getIBC();
    }else{
      this.props.getFilterIBC(channelValue);
    }
  }

  render() {
    return(
      <div className="contents">
        <p className="content-title">Summary</p>
        <Summary/>
        <p className="content-title">IBC Transactions</p>
        {
          this.state.data ?
            <div className="row" style={{"marginBottom": "10px"}}>
              <div className="col-md-3 col-6">
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-button" id="dropdown-basic">
                    From {this.state.channelFromText}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu overflow-custom">
                    <Dropdown.Item className="dropdown-item" onClick={() => this.handleChannel(`all`,"all","all")} style={{"textTransform": "capitalize"}}><i className="bi bi-circle-fill" style={{"color":"green","fontSize":"6px"}}></i> all</Dropdown.Item>
                    {
                      (this.state.data).map(ch => {
                        const from = (ch.from).replace("-", " ");
                        const to = (ch.to).replace("-", " ");
                        var textColor;
                        if(ch.status === "STATE_OPEN"){
                          textColor = "green"
                        }else if(ch.status === "STATE_INIT"){
                          textColor = "grey"
                        }else if(ch.status === "STATE_TRYOPEN"){
                          textColor = "red"
                        }
                        if(ch.to){
                          return (<Dropdown.Item className="dropdown-item" onClick={() => this.handleChannel(`${ch.from}`,from,to)} style={{"textTransform": "capitalize"}}><i className="bi bi-circle-fill" style={{"color":textColor, "fontSize":"6px","verticalAlign": "middle"}}></i> {from}</Dropdown.Item>)
                        }else{
                          return (<Dropdown.Item className="dropdown-item" onClick={() => this.handleChannel(`${ch.from}`,from,to)} style={{"textTransform": "capitalize"}} disabled><i className="bi bi-circle-fill" style={{"color":textColor, "fontSize":"6px","verticalAlign": "middle"}}></i> {from}</Dropdown.Item>)
                        }
                      })
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-2 col-6">
                <label className="switch" for="checkbox">
                  <input className="checkbox" type="checkbox" id="checkbox" checked={this.state.chainId} onChange={(e) => this.setState({chainID: e.target.checked})}/>
                  <div className="slider round"></div>
                </label>
                <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Chain ID</p>
              </div>
              <div className="col-md-2 col-4">
                <label className="switch" for="checkbox2">
                  <input className="checkbox" type="checkbox" id="checkbox2" checked={this.state.sender} onChange={(e) => this.setState({sender: e.target.checked})}/>
                  <div className="slider round"></div>
                </label>
                <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Sender</p>
              </div>
              <div className="col-md-2 col-4">
                <label className="switch" for="checkbox3">
                  <input className="checkbox" type="checkbox" id="checkbox3" checked={this.state.channel} onChange={(e) => this.setState({channel: e.target.checked})}/>
                  <div className="slider round"></div>
                </label>
                <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Channel</p>
              </div>
              <div className="col-md-2 col-4">
                <label className="switch" for="checkbox4">
                  <input className="checkbox" type="checkbox" id="checkbox4" checked={this.state.block} onChange={(e) => this.setState({block: e.target.checked})}/>
                  <div className="slider round"></div>
                </label>
                <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Block</p>
              </div>
            </div>
          : null
        }
        <div className="shadow-sm border" style={{"background": "white","paddingTop":"10px","borderRadius": "10px"}}>
          {
            (this.props.fullData.latestIBC).length > 0 ?
              <IBC total="20" chainId={this.state.chainID} sender={this.state.sender} channel={this.state.channel} block={this.state.block} />
            : 
              <div className="summary-home content shadow-sm" style={{"textAlign": "center"}}>
                <div class="o1"></div>
                <div class="o2"></div>
                <div class="o3"></div>
              </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getIBC,
  getFilterIBC
}

export default connect(mapStateToProps, mapDispatchToProps)(IBCS);