import React from 'react';
import '../assets/css/styles.css';
import { apiURL, prefixDefault, denomination, projectName } from '../data/API';
import moment from 'moment'
import Axios from 'axios';
import Tooltip from "@material-ui/core/Tooltip";
import Label from '../components/Label';

class Transaction extends React.Component {
  state={}
  componentDidMount(){
    Axios.get(`${apiURL}/tx?hash=${this.props.match.params.hash}`)
    .then(response => {
      this.setState({
        status: response.data.data.status,
        height: response.data.data.txHeight,
        hash: response.data.data.txHash,
        time: response.data.data.txTime,
        amount: response.data.data.txAmount,
        type: response.data.data.txType,
        sender: response.data.data.txSender,
        receiver: response.data.data.txReceiver,
        gasUsed: response.data.data.txGasUsed,
        gasWanted: response.data.data.txGasWanted,
        fee: response.data.data.txFee,
        memo: response.data.data.txMemo
      })
      if(response.data.data.txAdditional && (response.data.data.txType === "create validator" || response.data.data.txType === "edit validator")){
        this.setState({
          operatorAddress: response.data.data.txAdditional.operatorAddress,
          moniker: response.data.data.txAdditional.info.moniker,
          identity: response.data.data.txAdditional.info.identity,
          website: response.data.data.txAdditional.info.website,
          details: response.data.data.txAdditional.info.details,
          fees: response.data.data.txAdditional.fee,
          maxFees: response.data.data.txAdditional.maxFees,
          keybase: response.data.data.txAdditional.keybase
        })
      }else if(response.data.data.txAdditional && (response.data.data.txType === "update client" || response.data.data.txType === "MsgUpdateClient" || response.data.data.txType === "transfer" || response.data.data.txType === "MsgTransfer")){
        this.setState({
          ibcFrom: response.data.data.txAdditional.from,
          ibcTo: response.data.data.txAdditional.to,
          denom: response.data.data.txAdditional.denom ? response.data.data.txAdditional.denom : denomination,
          fromChannel: response.data.data.txAdditional.fromChannel,
          toChannel: response.data.data.txAdditional.toChannel,
          fromPort: response.data.data.txAdditional.fromPort,
          toPort: response.data.data.txAdditional.toPort,
          ibcAmount: response.data.data.txAdditional.amount,
          connection: response.data.data.txAdditional.connection,
          ibcHeight: response.data.data.txAdditional.height,
          chainId: response.data.data.txAdditional.chainId,
          signer: response.data.data.txAdditional.signer,
        })
      }else if(response.data.data.txAdditional && response.data.data.txType === "submit proposal"){
        this.setState({
          title: response.data.data.txAdditional.title,
          description: response.data.data.txAdditional.description,
          initialDeposit: response.data.data.txAdditional.initialDeposit,
          params: response.data.data.txAdditional.params,
        })
      }
    })
    document.title = `Transaction ${this.props.match.params.hash} | ${projectName} Scan`;
  }

  render() {
    console.log(this.state.description);
    return (
      <div className="contents">
        <p className="content-title">Transaction Details</p>
        {
          this.state.status ?
          <>
            <div className="summary-home shadow-sm">
              <Label label="Hash" value={this.state.hash} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.hash)}}></i>} tooltip />
              <div className="row">
                <div className="col-12 col-md-2">
                  <p className="content-table-title">Status</p>
                </div>
                <div className="col-12 col-md-10">
                  {
                    this.state.status === true ?
                      <p className="block-detail-left" style={{"color": "green"}}><i className="icon bi-check-circle-fill"></i><b> Confirmed</b></p>
                    :
                    <>
                    <Tooltip interactive title={this.state.status} placement="top-start">
                      <p className="block-detail-left" style={{"color": "red"}}><i className="icon bi-x-circle-fill"></i><b> Failed</b></p>
                    </Tooltip>
                    </>
                  }
                </div>
              </div>
              <Label label="Block" value={parseInt(this.state.height).toLocaleString()} linkTo={`/block/${this.state.height}`} link />
              <Label label="Time" value={`${moment(this.state.time).format("DD/MM/YY, HH:mm:ss")} (${moment(this.state.time).fromNow()})`} />
              <Label label="Type" value={<b style={{"textTransform": "capitalize", "fontWeight": "normal"}}>{this.state.type}</b>} capitalize />
              {
                this.state.sender ?
                  (this.state.sender).includes(prefixDefault) ?
                    (this.state.sender).includes("valoper") ?
                      <Label label="From" value={this.state.sender} linkTo={`/validator/${this.state.sender}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.sender)}}></i>} link tooltip />
                    : <Label label="From" value={this.state.sender} linkTo={`/address/${this.state.sender}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.sender)}}></i>} link tooltip />
                  : null
                : null
              }
              {
                this.state.receiver ?
                  (this.state.receiver).includes(prefixDefault) ?
                    (this.state.receiver).includes("valoper") ?
                    <Label label="To" value={this.state.receiver} linkTo={`/validator/${this.state.receiver}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.receiver)}}></i>} link tooltip />
                    : <Label label="To" value={this.state.receiver} linkTo={`/address/${this.state.receiver}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.receiver)}}></i>} link tooltip />
                  : null
                : null
              }
              <Label label="Total" value={(parseInt(this.state.amount)/10**6).toLocaleString(undefined, {minimumFractionDigits: 5})} denom />
              <Label label="Transaction Fee" value={(parseInt(this.state.fee)/10**6).toLocaleString(undefined, {minimumFractionDigits: 5})} denom />
              <Label label="Gas Used" value={parseInt(this.state.gasUsed).toLocaleString()} percentageValue={`${(parseInt(this.state.gasUsed)/parseInt(this.state.gasWanted)*100).toLocaleString(undefined, {minimumFractionDigits: 2})}%`} percentage />
              <Label label="Gas Wanted" value={parseInt(this.state.gasWanted).toLocaleString()} />
              {
                this.state.memo ?
                  <Label label="Memo" value={this.state.memo} />
                : null
              }
              {
                this.state.operatorAddress ?
                  <>
                  <p className="content-title">Validator Details</p>
                  <Label label="Operator Address" value={this.state.operatorAddress} linkTo={`/validator/${this.state.operatorAddress}`} tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.operatorAddress)}}></i>} tooltipLabel="Copy" tooltip link />
                  <Label label="Moniker" value={this.state.moniker} />
                  <Label label="Identity" value={this.state.identity !== "-" ? this.state.identity : "-"} />
                  <Label label="Website" value={this.state.website !== "-" ? <a className="content-table-link" href={(this.state.website).includes("https://") || (this.state.website).includes("http://") ? this.state.website : `https://${this.state.website}`} target="_blank" rel="noreferrer">{this.state.website}</a> : "-"} />
                  <Label label="Details" value={this.state.details} />
                  {
                    this.state.fees !== null ?
                    <>
                      <Label label="Commission" value={`${(parseFloat(this.state.fees)*100).toLocaleString(undefined, {minimumFractionDigits: 2})}%`} />
                    </>
                    : null
                  }
                  {
                    this.state.maxFees !== null ?
                    <>
                      <Label label="Max. Commission" value={`${(parseFloat(this.state.maxFees)*100).toLocaleString(undefined, {minimumFractionDigits: 2})}%`} />
                    </>
                    : null
                  }
                  </>
                : this.state.fromChannel ? 
                  <>
                  <p className="content-title">IBC Details</p>
                  {
                    this.state.signer ?
                      (this.state.signer).includes(prefixDefault) ?
                        <Label label="Signer" value={this.state.signer} linkTo={`/address/${this.state.signer}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.signer)}}></i>} link tooltip />
                      : <Label label="Signer" value={this.state.signer} />
                    : null
                  }
                  {
                    this.state.ibcFrom ?
                      (this.state.ibcFrom).includes(prefixDefault) ?
                        (this.state.ibcFrom).includes("valoper") ?
                          <Label label="From" value={this.state.ibcFrom} linkTo={`/validator/${this.state.ibcFrom}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.ibcFrom)}}></i>} link tooltip />
                        : <Label label="From" value={this.state.ibcFrom} linkTo={`/address/${this.state.ibcFrom}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.ibcFrom)}}></i>} link tooltip />
                      : <Label label="From" value={this.state.ibcFrom} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.ibcFrom)}}></i>} tooltip />
                    : null
                  }
                  {
                    this.state.ibcTo ?
                      (this.state.ibcTo).includes(prefixDefault) ?
                        (this.state.ibcTo).includes("valoper") ?
                        <Label label="To" value={this.state.ibcTo} linkTo={`/validator/${this.state.ibcTo}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.ibcTo)}}></i>} link tooltip />
                        : <Label label="To" value={this.state.ibcTo} linkTo={`/address/${this.state.ibcTo}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.ibcTo)}}></i>} link tooltip />
                      : <Label label="To" value={this.state.ibcTo} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.ibcTo)}}></i>} tooltip />
                    : null
                  }
                  <Label label="Height" value={parseInt(this.state.ibcHeight).toLocaleString()} />
                  <div className="row">
                    <div className="col-12 col-md-2">
                      <p className="content-table-title">Total</p>
                    </div>
                    <div className="col-12 col-md-10">
                      <p className="block-detail-left">{(parseInt(this.state.ibcAmount)/10**6).toLocaleString()} <b>{this.state.denom}</b></p>
                    </div>
                    <div className="col-12 col-md-2">
                      <p className="content-table-title">Channel</p>
                    </div>
                    <div className="col-12 col-md-10">
                      <p className="block-detail-left">{this.state.fromChannel} <i className="icon bi-arrow-right" style={{"color": "blue"}}></i> {this.state.toChannel}</p>
                    </div>
                    <div className="col-12 col-md-2">
                      <p className="content-table-title">Port</p>
                    </div>
                    <div className="col-12 col-md-10">
                      <p className="block-detail-left">{this.state.fromPort} <i className="icon bi-arrow-right" style={{"color": "blue"}}></i> {this.state.toPort}</p>
                    </div>
                  </div>
                  { 
                    this.state.chainId ?
                      <Label label="Chain ID" value={this.state.chainId} />
                    : null
                  }
                  </>
                : this.state.title ?
                <>
                  <p className="content-title">Proposal Details</p>
                  <Label label="Title" value={this.state.title}/>
                  <Label label="Description" value={this.state.description}/>
                  <Label label="Initial Deposit" value={(parseInt(this.state.initialDeposit)/10**6).toLocaleString(undefined, {minimumFractionDigits: 5})} denom/>
                  {
                    this.state.params ?
                      (this.state.params).map(params => {
                        return(
                          <>
                          <Label label="Subspace" value={params.subspace}/>
                          <Label label="Key" value={params.key}/>
                          <Label label="Value" value={params.value}/>
                          </>
                        )
                      })
                    : null
                  }
                </>
                : null
              }
            </div>
          </>
          :
          <div className="summary-home shadow-sm" style={{"textAlign": "center"}}>
            <div class="o1"></div>
            <div class="o2"></div>
            <div class="o3"></div>
          </div>
        }
      </div>
    )
  }
}

export default Transaction;