import React from 'react';
import Summary from '../components/Summary';
import Transaction from '../components/Transaction';
import '../assets/css/styles.css';
import { projectName } from '../data/API';

class Transactions extends React.Component {
  state = {
    channelValue: "all",
    channelFromText: "All",
    channelToText: "All",
    sender: false,
    block: false,
    gas: false
  }
  componentDidMount(){
    document.title = `Transactions | ${projectName} Scan`;
  }
  render() {
    return(
      <div className="contents">
        <p className="content-title">Summary</p>
        <Summary/>
        <p className="content-title">Transactions</p>
        <div className="row" style={{"marginBottom": "10px"}}>
          <div className="col-md-2 col-4">
            <label className="switch" for="checkbox">
              <input className="checkbox" type="checkbox" id="checkbox" checked={this.state.sender} onChange={(e) => this.setState({sender: e.target.checked})}/>
              <div className="slider round"></div>
            </label>
            <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Receiver</p>
          </div>
          <div className="col-md-2 col-4">
            <label className="switch" for="checkbox2">
              <input className="checkbox" type="checkbox" id="checkbox2" checked={this.state.block} onChange={(e) => this.setState({block: e.target.checked})}/>
              <div className="slider round"></div>
            </label>
            <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Time</p>
          </div>
          <div className="col-md-2 col-4">
            <label className="switch" for="checkbox3">
              <input className="checkbox" type="checkbox" id="checkbox3" checked={this.state.gas} onChange={(e) => this.setState({gas: e.target.checked})}/>
              <div className="slider round"></div>
            </label>
            <p style={{"position": "abosolute","marginLeft":"50px","marginTop":"6px"}}> Gas</p>
          </div>
        </div>
        <div className="shadow-sm border" style={{"background": "white","paddingTop":"10px","borderRadius": "10px"}}>
          <Transaction total="20" sender={this.state.sender} block={this.state.block} gas={this.state.gas}/>
        </div>
      </div>
    )
  }
}

export default Transactions;