import React from 'react';
import Axios from 'axios';
import { apiURL, prefixDefault, prefixValidator, projectName } from '../data/API';
import Summary from '../components/Summary';
import Block from '../components/Block';
import Transaction from '../components/Transaction';
import Validator from '../components/Validator';
import '../assets/css/styles.css';

class Home extends React.Component {
  state={
  }
  componentDidMount(){
    document.title = `${projectName} Scan`;
  }
  searchHandle(searchString){
    if(searchString){
      if(Number.isInteger(parseInt(searchString)) && searchString.length < 44){
        window.location.replace(`/block/${parseInt(searchString)}`);
      }else if(searchString.length === prefixDefault.length+39 && searchString.includes(prefixDefault)){
        window.location.replace(`/address/${searchString}`);
      }else if(searchString.length === prefixValidator.length+39 && searchString.includes(prefixValidator)){
        window.location.replace(`/validator/${searchString}`);
      }else if(searchString.length === 64){
        Axios.get(`${apiURL}/search?hash=${searchString}`)
        .then(getType => {
          if(getType.data.result){
            if(getType.data.data === 'transaction'){
              window.location.replace(`/tx/${searchString}`);
            }else if(Number.isInteger(getType.data.data)){
              window.location.replace(`/block/${getType.data.data}`);
            }
          }
        })
      }
    }
  }
  handleKeypress(e){
    if (e.keyCode === 13) {
      this.searchHandle();
    }
  };
  render() {
    return(
      <div>
        <div className="jumbo">
          <p className="home-title text-center text-white" style={{"marginLeft":"-30px"}}>{projectName} Block Explorer</p>
          <div className="home-search-div mx-auto my-auto">
            <input type="text" className="home-search mx-auto" onChange={e => this.setState({searchString: e.target.value})} onKeyPress={this.handleKeypress} placeholder="Search by hash, block height, address..."/>
            <button className="home-search-button" type="submit" onClick={() => this.searchHandle(this.state.searchString)}><i className="icon bi-search"></i><b className="nonmobile" style={{"fontWeight": "normal"}}> Search</b></button>
          </div>
        </div>
        <div className="contents">
          <Summary/>
          <div className="row">
            <div className="col-md-6">
              <div className="pr-5">
                <p className="content-title">Latest Block</p>
                <Block total="5" grid/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pl-5">
                <p className="content-title">Latest Transaction</p>
                <Transaction total="5" grid/>
              </div>
            </div>
            <div className="col-md-12">
              <div className="pl-5">
                <p className="content-title" style={{"marginTop":"15px"}}>Top Validator</p>
                <Validator total="5" status="active"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home;