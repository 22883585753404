import Axios from 'axios';
import { apiURL } from '../../data/API';

export const getPathName = (pathName) => {
  return(dispatch) => {
    dispatch({
      type: 'GET_PATH_NAME',
      data: pathName
    })
  }
}
export const getBlocks = () => {
  return async (dispatch) => {
    let response = await Axios.get(`${apiURL}/blocks`)
    if(response.data.result === true) {
      const blocksData = response.data.data.blocks;
      dispatch({
        type: 'GET_BLOCK',
        status: response.status,
        data: blocksData
      })
    }else{
      console.log("Error fetching block!")
    }
  }
}
export const getValidators = () => {
  return async(dispatch) => {
    let response = await Axios.get(`${apiURL}/validators`)
    if(response.data.result === true) {
      const validatorsData = response.data.data.result;
      var activeValidators = [];
      var inactiveValidators = [];
      validatorsData.forEach((validator) => {
        if(validator.statusDetails.status === "active") {
          activeValidators.push(validator);
        }else{
          inactiveValidators.push(validator);
        }
      });
      activeValidators.sort(function(a, b) {
        return a.delegated - b.delegated
      });
      activeValidators.reverse();
      inactiveValidators.sort(function(a, b) {
        return a.delegated - b.delegated
      });
      inactiveValidators.reverse();
      dispatch({
        type: 'SUMMARY_VALIDATOR',
        data: {
          activeValidators,
          inactiveValidators
        }
      })
    }else{
      console.log("Error fetching validator!")
    }
  }
}
export const getTransactions = () => {
  return async(dispatch) => {
    let response = await Axios.get(`${apiURL}/txs`)
    if(response.data.result === true) {
      const txsData = response.data.data;
      dispatch({
        type: 'GET_TRANSACTION',
        status: response.status,
        data: txsData
      })
    }else{
      console.log("Error fetching txs!")
    }
  }
}
export const getIBC = () => {
  return async(dispatch) => {
    let response = await Axios.get(`${apiURL}/ibc`)
    var txData = response.data.data
    txData.sort(function(a, b) {
      return a.txHeight - b.txHeight
    });
    txData.reverse()
    if(response.data.result){
      dispatch({
        type: 'GET_IBC',
        status: response.status,
        data: txData,
      })
    }else{
      console.log("Error fetching ibc!")
    }
  }
}
export const getFilterIBC = (channel) => {
  return async(dispatch) => {
    let response = await Axios.get(`${apiURL}/ibc?channel=${channel}`)
    var txData = response.data.data
    txData.sort(function(a, b) {
      return a.txHeight - b.txHeight
    });
    txData.reverse()
    if(response.data.result){
      dispatch({
        type: 'GET_IBC',
        status: response.status,
        data: txData,
      })
    }else{
      console.log("Error fetching ibc!")
    }
  }
}
export const getSummary = () => {
  return async(dispatch) => {
    let response = await Axios.get(`${apiURL}/status`)
    dispatch({
      type: 'SUMMARY_MOST',
      data:{
        maxValidator: response.data.maxValidators,
        syncStatus: response.data.syncStatus,
        totalSupply: response.data.totalSupply,
        bondedCoins: response.data.bondedCoins,
        blockFirst: response.data.blockFirst,
      },
    })
  }
}